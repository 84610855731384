import $ from 'jquery';
import { buildLink } from "./affiliate_links";

// Set the link set to be used.
// this can later be a/b split tested
const LINK_SET = 'share_a_sale_links';

$(function() {
  $('body').on('click', '.affiliate-link', function($event) {
    $event.preventDefault();

    const section = $(this).data('section');
    // const link_set = $(this).data('link-set');
    const link = buildLink(LINK_SET, section);
    console.log('link', link);
    window.location.href = link;
  });
});