const links  = { 
  share_a_sale_links: {
    base_urls: {
      default: `https://t.verygoodgold.com/mm-gold`,
    },
    // hero_links: [],
    // menu_links: [],
    // right_sidebar_links: [],
    // pricing_links: [],
    // request_more_info_links: [],
    // why_gold_links: [],
    hero: `hero`,
    menu: `main_nav`,
    right_sidebar: `r_sb`,
    sb_lower: 'sb_lower',
    pricing: `f_price`,
    request_more_info: ``,
    why_gold: `why_gold`,
    menu_pricing: ``,
    request_info: `request_info`,
  },
};

export default links;

/**
 * @returns {string} - The affiliate link
 * 
 * the link_set is the key to the links object
 * the section is the to differentiate which link initiated the click
 * the cmg_link is the key to the base_urls object which could be different
 * clickmagick links to test different redirect pages
 */
export function buildLink(link_set, section, cmg_link = 'default') {
  let scoped_links = links[link_set];
  let base_url = scoped_links.base_urls[cmg_link];

  return `${base_url}/${scoped_links[section]}`;
}